/* Holds new woop & sign in dialog logic for the desktop dashboard */
define('viewmodels/components/dashboard-dialogs',['require','knockout','durandal/app','lodash','plugins/router','plugins/dialog','services/state-container','toastr','utils/string','models/woop','services/customer','services/user','viewmodels/mixins/busy','viewmodels/components/panda','viewmodels/components/benefits','viewmodels/base','viewmodels/pages/sauna-landing-page','viewmodels/pages/bike-landing-page','viewmodels/pages/landing-page'],function(require) {
    var ko = require('knockout');
    var app = require('durandal/app');
    var _ = require('lodash');
    var router = require('plugins/router');
    var dialog = require('plugins/dialog');
    var state = require('services/state-container');
    var toastr = require('toastr');
    var stringUtils = require('utils/string');
    var Woop = require('models/woop');
    var customerService = require('services/customer');
    var userService = require('services/user');
    var BusyMixin = require('viewmodels/mixins/busy');
    var PandaViewModel = require('viewmodels/components/panda');
    var BenefitsViewModel = require('viewmodels/components/benefits');
    var BaseViewModel = require('viewmodels/base');
    var SaunaLandingPageViewModel = require('viewmodels/pages/sauna-landing-page');
    var BikeLandingPageViewModel = require('viewmodels/pages/bike-landing-page');
    var LandingPageViewModel = require('viewmodels/pages/landing-page');

    var DashboardDialogsViewModel = function() {
        var self = this;
        _.assign(self, new BusyMixin());

        self.targetKeyword = ko.observable(null);
        self.keywords = ko.observable();
        self.panda = new PandaViewModel(self.keywords, self.targetKeyword);
        self.benefits = new BenefitsViewModel(true);

        self.isShowingSignInDialog = ko.observable(false);
        self.isVisible = ko.pureComputed(function() {
            if (router.activeItem() instanceof SaunaLandingPageViewModel) return false;
            if (router.activeItem() instanceof BikeLandingPageViewModel) return false;
            if (router.activeItem() instanceof LandingPageViewModel) return false;
            if (state.isLoggedIn()) return false;
            if (self.isShowingSignInDialog()) return false;
            return true;
        });

        self.go = function() {
            self.onNewWoop(self.keywords());
        };

        self.isGoButtonEnabled = ko.pureComputed(function() {
            if (stringUtils.isEmpty(self.keywords())) return false;
            return true;
        });

        var addWoop = function(keywords, targetKeyword) {
            customerService.addWoop({ keywords: keywords, targetKeyword: targetKeyword }, function(err, woopId) {
                if (err) return toastr.error(err);
                toastr.success('Nieuwe woop is geplaatst');
            });
        };

        self.onNewWoop = function(keywords) {
            if (state.isLoggedIn()) addWoop(keywords, self.targetKeyword());
            else {
                self.keywords(keywords);
                state.woops([]);
                state.firstWoop.keywords(self.keywords());
                state.firstWoop.targetKeyword(self.targetKeyword());
                state.woops.push(Woop.createNew(self.keywords(), self.targetKeyword()));
                self.signIn();
            }
        };

        self.signIn = function() {
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/sign-in-dialog', { callback: self.onSignIn });
            self.isShowingSignInDialog(true);
        };

        self.onSignIn = function() {
            self.isShowingSignInDialog(false);
            if (!stringUtils.isEmpty(self.keywords())) {
                addWoop(self.keywords(), self.targetKeyword());
                self.keywords(null);
            }
        };

        self.forgotPassword = function(accessToken) {
            userService.useForgotPasswordSession(accessToken);
            dialog.getContext().blockoutOpacity = 0.66;
            dialog.show('viewmodels/dialogs/new-password-dialog');
        };

        self.activate = function() {
            var context = router.activeInstruction();
            var params = context.params[1]
            if (params && params.src && params.access_token) {
                if (params.src === 'forgotpass') return self.forgotPassword(params.access_token);
            }
        };

    };

    DashboardDialogsViewModel.prototype = new BaseViewModel();
    return DashboardDialogsViewModel;
});

