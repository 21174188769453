define('viewmodels/components/lead-form-data',['require','lodash','knockout','viewmodels/base','models/sauna-form','models/bike-form'],function(require) {
    var _ = require('lodash');
    var ko = require('knockout');
    var BaseViewModel = require('viewmodels/base');
    var formModelSauna = require('models/sauna-form');
    var formModelBike = require('models/bike-form');

    var LeadFormData = function(woop, useSmallLayout, newWoopFormType, isReadOnly) {
        var self = this;
        var isNew = !!newWoopFormType;

        self.woop = woop;
        self.isReadOnly = isReadOnly;

        self.smallLayout = ko.observable(useSmallLayout);
        self.infoFlags = ko.observableArray([]);

        if (!isNew && self.woop && self.woop.formData()) {
            self.formModel = JSON.parse(self.woop.formData()).map(x => x.map(y => { y.info = y.info || false; return y; }));
            const checked = _.flatten((self.formModel || []).map(function(column) { 
                return column.filter(function(row) { 
                    return row.type === 'check' && row.value; 
                })
                .map(function(row) {
                    return row.key;
                });
            }));
            self.vals = ko.observableArray(checked);
        }
        else if (isNew) {
            self.formModel = newWoopFormType === 'sauna' ? formModelSauna : (newWoopFormType === 'bike' ? formModelBike : undefined);
            self.vals = ko.observableArray([]);
        }

        self.showInfo = function(item) {
            self.infoFlags.removeAll();
            self.infoFlags.push(item.key);
        };

        self.hideInfo = function() {
            self.infoFlags.removeAll();
        };

        self.export = function() {
            return _.flatten(self.formModel.map(function(column) {
                return column.map(function(row) {
                    if (row.type === 'header') return row;
                    return { 
                        type: row.type, 
                        desc: row.desc, 
                        value: self.vals().indexOf(row.key) !== -1 
                    };
                });
            }));
        }

        self.serialized = ko.pureComputed(function() {

            return JSON.stringify(self.formModel.map(function(column) {
                return column.map(function(row) {
                    if (row.type === 'header') return row;
                    return { 
                        type: row.type, 
                        desc: row.desc, 
                        key: row.key,
                        info: row.info,
                        value: self.vals().indexOf(row.key) !== -1 
                    };
                });
            }));
        });
    };

    LeadFormData.prototype = new BaseViewModel();
    return LeadFormData;
});

